import Vue from 'vue';
import App from './App.vue';
import store, {StoreState} from '@/configurator/store';
import LocalTranslationSource from '@/common/translations/local-translation-source';
import LocalizePlugin from '@/common/plugins/localize';
import SdkConnectorPlugin from '@/configurator/plugins/sdk-connector-plugin';
import RoomleSdkWrapperPlugin from '@/configurator/plugins/roomle-sdk-wrapper-plugin';
import AnalyticsPlugin, {Analytics} from '@/common/plugins/analytics';

import {RoomleSdkWrapper} from '@/configurator/business-logic/roomle-sdk-wrapper';
import {SdkConnector} from '@/configurator/business-logic/sdk-connector';
import {isInIframe, useEmbeddingApi} from '@/common/utils/iframe';
import {combineInitData, getInitData} from '@/common/utils/init-data';
import {calcIsDesktop} from '@/common/utils/dom';
import EmbeddingCallbacksPlugin from '@/configurator/plugins/embedding-callbacks-plugin';
import {ExposedCallbacks} from '@/configurator/embedding/exposed-callbacks';
import {UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import {EmbeddingSkin, UiInitData} from '@/configurator/embedding/types';
import {GA_DIMENSIONS} from '@/common/utils/google-analytics';
import {prepareSkin} from '@/common/utils/color';
import {version} from './../package.json';
// @ts-ignore
import {blackText, whiteText} from '@/common/styles/Global.module.scss';
import LibsPlugin, {Libs} from '@/configurator/plugins/libs-plugin';
import {checkDeprecations} from '@/common/utils/compatibility';

import customConfig from '@/common/custom-config.json';
import configCaraBlade from '@/common/config-cara-blade.json';
import configCaraCover from '@/common/config-cara-cover.json';
import configCaraSlope from '@/common/config-cara-slope.json';
import configQoroBlade from '@/common/config-qoro-blade.json';
import configQoroCover from '@/common/config-qoro-cover.json';
import configQoroSlope from '@/common/config-qoro-slope.json';
import configMurale from '@/common/config-murale.json';
import configNano from '@/common/config-nano.json';
import configLitho from '@/common/config-litho.json';
import configMuraleBoard from '@/common/config-murale-board.json';
import configNanoBoard from '@/common/config-nano-board.json';
import configApuaBoard from '@/common/config-apua-board.json';
import configLithoBoard from '@/common/config-litho-board.json';
import configTremezzo from '@/common/config-tremezzo.json';
import configOneUp from '@/common/config-one-up.json';
// aure
import configAure from '@/common/config-aure.json';
import configAureBowlOnTop from '@/common/config-aure-bowl-on-top.json';
import configAureFloatingDuo from '@/common/config-aure-floating-duo.json';
import configAureSideBySide from '@/common/config-aure-side-by-side.json';
import configAureEN from '@/common/config-en/config-aure.json';
import configAureBowlOnTopEN from '@/common/config-en/config-aure-bowl-on-top.json';
import configAureFloatingDuoEN from '@/common/config-en/config-aure-floating-duo.json';
import configAureSideBySideEN from '@/common/config-en/config-aure-side-by-side.json';

// lax
import configLax from '@/common/config-lax.json';
import configLaxBowlOnTop from '@/common/config-lax-bowl-on-top.json';
import configLaxFloatingDuo from '@/common/config-lax-floating-duo.json';
import configLaxMeetsFloor from '@/common/config-lax-meets-floor.json';
import configCaraBladeEN from '@/common/config-en/config-cara-blade.json';
import configCaraCoverEN from '@/common/config-en/config-cara-cover.json';
import configCaraSlopeEN from '@/common/config-en/config-cara-slope.json';
import configQoroBladeEN from '@/common/config-en/config-qoro-blade.json';
import configQoroCoverEN from '@/common/config-en/config-qoro-cover.json';
import configQoroSlopeEN from '@/common/config-en/config-qoro-slope.json';
import configMuraleEN from '@/common/config-en/config-murale.json';
import configNanoEN from '@/common/config-en/config-nano.json';
import configLithoEN from '@/common/config-en/config-litho.json';
import configMuraleBoardEN from '@/common/config-en/config-murale-board.json';
import configNanoBoardEN from '@/common/config-en/config-nano-board.json';
import configApuaBoardEN from '@/common/config-en/config-apua-board.json';
import configLithoBoardEN from '@/common/config-en/config-litho-board.json';
import configTremezzoEN from '@/common/config-en/config-tremezzo.json';
import configOneUpEN from '@/common/config-en/config-one-up.json';
import configLaxEN from '@/common/config-en/config-lax.json';
import configLaxBowlOnTopEN from '@/common/config-en/config-lax-bowl-on-top.json';
import configLaxFloatingDuoEN from '@/common/config-en/config-lax-floating-duo.json';
import configLaxMeetsFloorEN from '@/common/config-en/config-lax-meets-floor.json';
import {deepMerge} from '@/common/utils/helper';

declare module 'vue/types/vue' {
  interface Vue {
    $roomleSdkWrapper: RoomleSdkWrapper;
    $sdkConnector: SdkConnector;
    $embeddingCallbacks: ExposedCallbacks;
    // @ts-ignore --- needed to get rid of ts error ts(2717),
    // this is because vue.d.ts defines Store<any> and therefore
    // we can not extend it
    $store: StoreState;
    $analytics: Analytics;
    $libs: Libs;
  }
}
// for info why process.env.NODE_ENV is available here see:
// https://cli.vuejs.org/guide/mode-and-env.html#using-env-variables-in-client-side-code
const isProductionBuild = process.env.NODE_ENV === 'production';
console.log('UI Version:', isProductionBuild ? version : 'development mode');

if (isProductionBuild) {
  Vue.config.productionTip = false;
  Vue.config.devtools = false; // for production use only https://laracasts.com/discuss/channels/elixir/disabling-vue-devtools-in-production
  Vue.config.silent = true;    // for production use only
}

Vue.use(RoomleSdkWrapperPlugin);
Vue.use(EmbeddingCallbacksPlugin);
Vue.use(LibsPlugin);

const bootApp = async (queryParams: UiInitData, inIframe: boolean) => {
  const sdkWrapper = Vue.prototype.$roomleSdkWrapper;
  // messageHandler stuff will be refacotred in:
  // https://roomle.atlassian.net/browse/RML-44
  const messageHandler: any = null;
  if (inIframe && useEmbeddingApi()) {
    // const {MessageHandler} = await import('@/configurator/embedding/message-handler');
    // messageHandler = new MessageHandler('iframe', window, window.parent, null as any);
  }
  const finalInitData = await combineInitData(messageHandler, sdkWrapper, {...queryParams});
  if (!inIframe) {
    // we will remove this if-query when we implement
    // the following ticket: https://roomle.atlassian.net/browse/RML-609
    finalInitData.gaConsent = 'true' === process.env.VUE_APP_GA_USE_TRACKING;
  }

  // Custom Config
  if (finalInitData.id) {
    let configs: any = {};

    if (finalInitData.locale === 'en') {
      configs = {
        basin_cara_blade: configCaraBladeEN,
        basin_cara_cover: configCaraCoverEN,
        basin_cara_slope: configCaraSlopeEN,
        basin_qoro_blade: configQoroBladeEN,
        basin_qoro_cover: configQoroCoverEN,
        basin_qoro_slope: configQoroSlopeEN,
        murale: configMuraleEN,
        basin_murale: configMuraleEN,
        nano: configNanoEN,
        basin_nano: configNanoEN,
        litho: configLithoEN,
        basin_litho: configLithoEN,
        murale_board: configMuraleBoardEN,
        board_murale: configMuraleBoardEN,
        nano_board: configNanoBoardEN,
        board_nano: configNanoBoardEN,
        apua_board: configApuaBoardEN,
        board_apua: configApuaBoardEN,
        litho_board: configLithoBoardEN,
        board_litho: configLithoBoardEN,
        tremezzo: configTremezzoEN,
        tremezzo_master: configTremezzoEN,
        oneup: configOneUpEN,
        oneup_master: configOneUpEN,
        aure: configAureEN,
        aure_master: configAureEN,
        aure_bowl_on_top: configAureBowlOnTopEN,
        aure_floating_duo: configAureFloatingDuoEN,
        aure_side_by_side: configAureSideBySideEN,
        lax: configLaxEN,
        lax_master: configLaxEN,
        lax_bowl_on_top: configLaxBowlOnTopEN,
        lax_floating_duo: configLaxFloatingDuoEN,
        lax_meets_floor: configLaxMeetsFloorEN,
        laxgo_master: configLaxMeetsFloorEN,
      };
    } else {
      configs = {
        basin_cara_blade: configCaraBlade,
        basin_cara_cover: configCaraCover,
        basin_cara_slope: configCaraSlope,
        basin_qoro_blade: configQoroBlade,
        basin_qoro_cover: configQoroCover,
        basin_qoro_slope: configQoroSlope,
        murale: configMurale,
        basin_murale: configMurale,
        nano: configNano,
        basin_nano: configNano,
        litho: configLitho,
        basin_litho: configLitho,
        murale_board: configMuraleBoard,
        board_murale: configMuraleBoard,
        nano_board: configNanoBoard,
        board_nano: configNanoBoard,
        litho_board: configLithoBoard,
        board_litho: configLithoBoard,
        apua_board: configApuaBoard,
        board_apua: configApuaBoard,
        tremezzo: configTremezzo,
        tremezzo_master: configTremezzo,
        oneup: configOneUp,
        oneup_master: configOneUp,
        aure: configAure,
        aure_master: configAure,
        aure_bowl_on_top: configAureBowlOnTop,
        aure_floating_duo: configAureFloatingDuo,
        aure_side_by_side: configAureSideBySide,
        lax: configLax,
        lax_master: configLax,
        lax_bowl_on_top: configLaxBowlOnTop,
        lax_floating_duo: configLaxFloatingDuo,
        lax_meets_floor: configLaxMeetsFloor,
        laxgo_master: configLaxMeetsFloor,
      };
    }

    let productKey = finalInitData.id.split(':')[1];
    const urlparams = new URLSearchParams(window.location.search);

    if (urlparams.get('model')) {
      productKey = urlparams.get('model') || productKey;
    }

    const defaultCustomConfig = customConfig.default;
    let productCustomConfig = {};

    productCustomConfig = configs[productKey];

    finalInitData.customConfig = deepMerge(defaultCustomConfig, productCustomConfig);
    // @ts-ignore
    window.customConfig = finalInitData.customConfig;
  }

  store.commit(UI_STATE_MUTATIONS.SET_INIT_DATA, finalInitData);
  sdkWrapper.setGlobalInitData(store.state.uiState.initData);

  Vue.use(AnalyticsPlugin, {configuratorId: finalInitData.configuratorId, useTracking: finalInitData.gaConsent});
  Vue.use(LocalizePlugin, {translationSource: new LocalTranslationSource(finalInitData.locale!, finalInitData.translations)});
  store.commit(UI_STATE_MUTATIONS.SET_IS_IN_IFRAME, inIframe);
  store.commit(UI_STATE_MUTATIONS.SET_IS_VIEW_ONLY, inIframe && !calcIsDesktop());
  const analytics = Vue.prototype.$analytics;

  const embeddingCallbacks = Vue.prototype.$embeddingCallbacks;
  Vue.use(SdkConnectorPlugin, {analytics, sdkWrapper, store, embeddingCallbacks});
  analytics.ga.setDimension(GA_DIMENSIONS.INIT_ID, finalInitData.id);
  analytics.ga.setDimension(GA_DIMENSIONS.CONFIGURATOR_ID, finalInitData.configuratorId);
  checkDeprecations(inIframe, finalInitData, analytics);
  prepareSkin(finalInitData, blackText, whiteText);

  for (const cssProperty in finalInitData.skin) {
    if (finalInitData.skin.hasOwnProperty(cssProperty)) {
      document.documentElement.style.setProperty('--' + cssProperty, finalInitData.skin[cssProperty as keyof EmbeddingSkin] as string);
    }
  }

  const app = new Vue({
    store,
    render: (h) => h(App),
  }).$mount('#app');

  // this stuff will be refacotred in:
  // https://roomle.atlassian.net/browse/RML-44
  if (inIframe) {
    app.$sdkConnector.setMessageHandler(messageHandler);
    const isViewer = (finalInitData as any).viewer || (finalInitData.id && await app.$sdkConnector.isIdAGlb(finalInitData.id));
    await app.$sdkConnector.createExposedApi(isViewer);
  }
  return app;
};
const startedInIframe = isInIframe();
// bootApp(getInitData(!startedInIframe), startedInIframe);
bootApp(getInitData(!startedInIframe), false);

